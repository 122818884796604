import React from 'react';
import Helmet from 'react-helmet';
import { SecondaryHero } from '@latitude/hero';
import Layout from '../components/layout';
import Faq from '../components/Faq/Faq';

const Page = props => (
  <Layout location={props.location}>
    <main className="navigation-spacer">
      <Helmet>
        <link rel="canonical" href="https://www.latitudefinancial.com.au/" />
        <meta
          name="description"
          content="Latitude Financial Services offers simple, flexible options to help you get ahead. From loans to credit cards to insurance, you can do better. Learn more."
        />
        <title>Frequently Asked Questions | Latitude Financial Services</title>
      </Helmet>

      <SecondaryHero heading="Frequently Asked Questions" />

      <Faq
        hideTitle
        data={[
          {
            title: 'Receiving statements',
            qa: [
              {
                q: 'When will I start receiving my statements?',
                a:
                  'We will be sending statements starting from mid-May 2018. You can expect to receive your statement before the end of June 2018.'
              },
              {
                q: 'How will I receive my statement?',
                a:
                  'If you have given us your email address then you will receive your statement by email. If we don’t have an email address for you, you will receive it by mail. Keep in mind, if you receive your first statement via mail, you can always call us on <a href="tel:132826">13 28 26</a> and update your email address so you can start receiving statements via email.'
              },
              {
                q: 'How will I be notified that my statement is ready?',
                a:
                  '<p>If you have supplied your email address, you will receive an email from us letting you know your statement is available. The email will provide you with a secure link to view your statement. You will be asked to enter your Date of Birth for verification. Once you have done this you will be able to securely view and download your statement.<br /><br />If we don’t have your email address, you will be sent your statement by post.'
              },
              {
                q: 'How is interest calculated?',
                a:
                  'As outlined in your loan contract terms and conditions, interest is calculated on the Outstanding Balance of your loan and is charged to the loan on a monthly basis. Please note: Interest charged to your loan may differ each month, as it is dependent on the number of days in the month, the applicable annual percentage rate and the Outstanding Balance of your loan.'
              }
            ]
          },
          {
            title: 'About my statement',
            qa: [
              {
                q:
                  'Is there a period of time I have to view the statement once I receive the email?',
                a:
                  'Once you receive the email, you will have 14 days to securely access your statement. If you do not access your statement within this time, then call one of our Customer Specialists on <a href="tel:132826">13 28 26</a> and they will be able to re-email you a statement.'
              },
              {
                q: 'What period will my statement be for?',
                a:
                  'Your statement will contain transactions from the last 6 months and you will receive these twice yearly.'
              },
              {
                q:
                  'What if I need a statement that is more up to date or for a longer period of time?',
                a:
                  'Give us a call on <a href="tel:132826">13 28 26</a> and we can arrange to have one sent to you.'
              },
              {
                q: 'What is ‘Outstanding Balance’?',
                a:
                  'Outstanding Balance is the total amount you have remaining on your Personal Loan at a specific given time, excluding accrued interest and fees.'
              }
            ]
          },
          {
            title: 'Balance and interest',
            qa: [
              {
                q:
                  'How do I query my balance, transactions, interest rate or fees on my statement?',
                a:
                  'Give us a call on <a href="tel:132826">13 28 26</a> and speak to one of our Customer Specialists.'
              },
              {
                q: 'What if I didn’t receive a statement?',
                a:
                  'Give us a call on <a href="tel:132826">13 28 26</a> and we will confirm we have your up to date information. We can then issue you a statement.'
              },
              {
                q: 'If I have a joint account, who will receive the statement?',
                a: 'The primary borrower will receive the statement.'
              },
              {
                q: 'Is the Outstanding Balance the same as a payout figure?',
                a:
                  'Outstanding Balance differs to a payout figure as a payout figure includes any accrued interest and any applicable fees such as an Early Termination Fee. To get an accurate payout figure, call us on <a href="tel:132826">13 28 26</a>.'
              }
            ]
          }
        ]}
      />
    </main>
  </Layout>
);

export default Page;
