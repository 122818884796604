/* eslint-disable react/no-danger */

import classnames from 'classnames';
import * as React from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import ReactHTMLParser from 'react-html-parser';
import styled from 'styled-components';
import {
  BREAKPOINT,
  COLOR,
  HEADING,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT
} from '@latitude/core/utils/constants';
import { Heading5 } from '@latitude/heading';
import { Accordion } from '@latitude/accordion';
import { Link } from '@latitude/link';
import Modal from '../Modal/Modal';
import Section from '../Section/Section';
import './faq.scss';

/* global document */

/**
 * Renders a data-driven Faq
 * Takes props in the following format:
 * data: [
 *   {
 *     "title": "Section 1 title",
 *     "qa": [
 *       {
 *         "q": "Question 1",
 *         "a": "<span>Answer 1</span>""
 *       },
 *       {
 *         "q": "Question 2",
 *         "a": "<span>Answer 2</span>""
 *       }
 *     ]
 *   },
 *   {
 *     "title": "Section 2 title",
 *     "qa": [
 *       {
 *         "q": "Question 3",
 *         "a": "<span>Answer 3</span>""
 *       },
 *       {
 *         "q": "Question 4",
 *         "a": "<span>Answer 4</span>""
 *       }
 *     ]
 *   }
 * ]
 *
 */

class Faq extends React.Component {
  state = {
    isModalOpen: false,
    currentFaqItemId: null
  };

  handleModalRequestClose = () => {
    this.setState({ isModalOpen: false });
  };

  handleFaqLinkClick = ev => {
    ev.preventDefault();
    this.setState({
      currentFaqItemId: ev.currentTarget.name,
      isModalOpen: true
    });
  };

  renderFaqList(section, sectionIndex) {
    return (
      <ul className="faq__group">
        {section.qa.map((item, itemIndex) => {
          const qaId = `qtip-${sectionIndex}${itemIndex}`;
          const isModalOpen =
            this.state.isModalOpen && this.state.currentFaqItemId === qaId;
          return (
            <li
              id={qaId}
              key={item.q}
              className="faq__item"
              data-autoheight={item.autoHeight}
            >
              {item.enableCrawlable ? (
                <div style={{ display: 'none' }}>
                  <p>{item.q}</p> <p>{item.a}</p>
                </div>
              ) : (
                ''
              )}
              <Link
                href="#"
                noStyle
                aria-describedby={qaId}
                name={qaId}
                onClick={this.handleFaqLinkClick}
                trackEventData={{
                  label: item.q
                }}
              >
                <span
                  className={`faq__item-text${
                    this.props.faqlinkColor ? '--faqLinkColor' : '--content'
                  }`}
                >
                  {item.q}
                </span>
              </Link>
              <div
                id={`${qaId}-modal`}
                style={{ display: isModalOpen ? 'block' : 'none' }}
              >
                <Modal
                  className="faq-modal"
                  isOpen={this.state.isModalOpen}
                  parentSelector={() =>
                    document.querySelector(`#${`${qaId}-modal`}`)
                  }
                  onRequestClose={this.handleModalRequestClose}
                  title={item.q}
                  content={<div dangerouslySetInnerHTML={{ __html: item.a }} />}
                  ariaHideApp={isModalOpen}
                />
              </div>
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    if (!this.props.data) return null;
    const cols = this.props.data.length === 4 ? 3 : 4;
    const title = this.props.title
      ? this.props.title
      : 'Frequently asked questions';

    return (
      <AnalyticsLocationProvider location={title}>
        <Section
          id="faq"
          className={classnames(
            'faq',
            this.props.className,
            this.props.autoHeightOnDesktop && 'faq--auto-height-desktop'
          )}
        >
          {!this.props.hideTitle && <h2 className="faq__heading">{title}</h2>}

          {/* default breakpoint */}
          <div className="faq__accordion bg-white">
            <Accordion
              titleBgColor="grey-light"
              fgColor="blue"
              borderedChildren
              items={this.props.data.map((section, sectionIndex) => ({
                id: section.id || `faq-${sectionIndex}`,
                title: section.title,
                content: section.qa.map((item, itemIndex) => ({
                  id: item.id || `faq-${sectionIndex}-${itemIndex}`,
                  title: item.q,
                  content: item.a
                }))
              }))}
            />
          </div>

          {/* small breakpoint and up */}
          <div className="row faq__lists justify-content-center">
            {this.props.data.map((section, sectionIndex) => (
              <div
                key={section.title}
                className={`col-12 col-lg-${cols} d-flex flex-column`}
              >
                <Heading5 color={COLOR.BLACK} className="faq__group-title">
                  {ReactHTMLParser(section.title)}
                </Heading5>
                {this.renderFaqList(section, sectionIndex)}
              </div>
            ))}
          </div>
        </Section>
      </AnalyticsLocationProvider>
    );
  }
}

const StyledBrandFaq = styled(Faq)`
  background: #f8f8f8;

  .faq__heading {
    font-family: ${FONT_FAMILY.TITLE};
    font-size: ${HEADING.h3.FONT_SIZE.NORMAL};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    color: ${COLOR.BLACK};
    line-height: 1.33;
    text-align: left;
    padding-left: 16px;
    margin-bottom: 16px;

    @media (min-width: ${BREAKPOINT.LG}) {
      font-size: ${HEADING.h4.FONT_SIZE.LARGE};
      line-height: 1.11;
      text-align: center;
      padding-left: 0;
      margin: 0;
    }
  }

  .faq__group-title {
    height: auto;
    padding-left: 16px;
    margin-top: 16px;

    @media (min-width: ${BREAKPOINT.LG}) {
      margin-top: 48px;
    }
  }

  .faq__item {
    height: 72px;
    > button {
      padding: 16px 0;
    }

    .modal__overlay {
      background-color: rgba(0, 0, 0, 0.5) !important;
    }
    .modal__title {
      line-height: 1.6;
    }
    .modal__panel {
      padding: 32px;
    }
    .modal__panel {
      line-height: 1.5;
    }
    .modal__header button {
      padding: 0;
    }
  }

  .faq__item-text--content {
    padding: 0 16px;
    line-height: 1.5;
    font-weight: ${FONT_WEIGHT.LIGHT};
  }

  .accordion--two-level .accordion__item {
    margin-bottom: 0;
  }

  .accordion--child.accordion--bordered .accordion__item {
    &:first-child {
      border-top: 1px solid #e9ebec;
    }
  }

  .faq__accordion.bg-white {
    background-color: transparent !important;
  }

  .accordion__item {
    margin-bottom: 0;

    &:not(.is-open) {
      border-bottom: 1px solid #e9ebec;
    }
    &:first-child {
      border-top: 1px solid #e9ebec;
    }

    button {
      min-height: 56px;
      background-color: transparent !important;
      font-size: ${FONT_SIZE.LARGE};
      font-weight: ${FONT_WEIGHT.MEDIUM} !important;
      line-height: 1.33;
      color: ${COLOR.BLACK};

      @media (min-width: ${BREAKPOINT.LG}) {
        font-size: 20px;
      }

      &:focus {
        outline-color: #ddd;
      }
    }

    .chevron svg .icon-line--style {
      stroke: ${COLOR.GREY75};
    }
  }

  .accordion--child {
    button {
      font-size: ${FONT_SIZE.NORMAL};
      font-weight: ${FONT_WEIGHT.LIGHT} !important;
    }
  }

  .accordion--child .accordion__item-body {
    padding: 0 16px 16px;
    font-size: ${FONT_SIZE.SMALL};
    line-height: 1.5;
    font-weight: 300;

    p:first-of-type {
      margin-top: 0;
    }

    &.bg-white {
      background-color: transparent !important;
    }
  }

  .accordion__button-fixed {
    position: relative !important;
    top: auto !important;
  }
`;

export default StyledBrandFaq;
